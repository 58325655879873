.card {
  width: 22%;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
  margin-bottom: 15px;
  margin-left: 2px;
}
.cardContainer {
  width: 100%;
}

// .cardEmailSms {
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }
.cardEmailSMS {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
}
.cardEmailSMSRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cardToggleContainer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 5px;
}
