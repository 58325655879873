.contactinfoContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.contactinfoEmail {
  display: flex;
  align-items: center;
}
.notificationmainContactInfo {
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
  margin: 2px 2px 15px 2px;
}
