.notificationmainpageWidth {
  width: 99%;
}
.notificationmainpageContainer {
  width: 100%;
  display: flex;
}
.notificationmainpageCardContainer {
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.35);
}
